import React from 'react';
import './styles/main.scss';

function App() {
  return (
    <div>
      <h1 className='comingSoon'>Coming Soon</h1>
    </div>
  );
}

export default App;
